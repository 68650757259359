@import url("https://fonts.googleapis.com/css2?family=Sora:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@300;400;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-sora: "Sora", sans-serif;
  --font-kufi: "Noto Kufi Arabic", sans-serif;
  --font-code: "Source Code Pro", monospace;
  --font-grotesk: "Space Grotesk", sans-serif;
  color-scheme:dark;
}

* {
  scroll-behavior: smooth
}

@layer base {
  body {
    @apply font-sans bg-n-8 text-n-1 text-base;
  }
}

.rotate-45 {
  @apply rotate-[45deg]
}

.rotate-90 {
  @apply rotate-[90deg]
}

.rotate-135 {
  @apply rotate-[135deg]
}

.rotate-180 {
  @apply rotate-[180deg]
}

.rotate-225 {
  @apply rotate-[225deg]
}

.rotate-270 {
  @apply rotate-[270deg]
}

.rotate-315 {
  @apply rotate-[315deg]
}

.rotate-360 {
  @apply rotate-[360deg]
}

.-rotate-45 {
  @apply rotate-[-45deg]
}

.-rotate-90 {
  @apply rotate-[-90deg]
}

.-rotate-135 {
  @apply rotate-[-135deg]
}

.-rotate-180 {
  @apply rotate-[-180deg]
}

.-rotate-225 {
  @apply rotate-[-225deg]
}

.-rotate-270 {
  @apply rotate-[-270deg]
}

.-rotate-315 {
  @apply rotate-[-315deg]
}

.-rotate-360 {
  @apply rotate-[-360deg]
}